import ArrowIcon from 'assets/arrow.svg';
import { Button, ButtonIconArrow, ButtonsContainer, ButtonText } from 'components/Button/Button';
import {
  ContainerContent,
  ContainerDescription,
  ContainerImgWrapper,
  ContainerItems,
} from 'components/ContainerItems/ContainerItems';
import { SectionHeading } from 'components/SectionHeading/SectionHeading';
import { SectionParagraph } from 'components/SectionParagraph/SectionParagraph';
import Img from 'gatsby-image';
import { Link, useIntl } from 'gatsby-plugin-intl';
import { useAboutQuery } from 'hooks/use-about-query';
import React from 'react';
import { Helmet } from 'react-helmet';
import PageTemplate from 'templates/PageTemplate';
import QuoteIcon from '../assets/quote.svg';
import HeroImage from '../components/HeroImage/HeroImage';
import { HeroSectionImage } from '../components/HeroImage/HeroImageStyles';
import * as Styled from '../components/Quote/QuoteStyles';
import { ContentRow, ContentWrapper } from '../components/SectionContent/SectionContentStyles';
import { useImageQuery } from '../hooks/use-image-query';

const About: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const aboutData = useAboutQuery();
  const dataImage = useImageQuery();

  let aboutIntro: string[] = [];
  let aboutDesc: string[] = [];

  const splitAbout = aboutData && aboutData[locale].content;
  const about = splitAbout.split(/<\/?p[^>]*>/g);

  aboutIntro.push(about[1], about[3]);
  aboutDesc.push(about[7], about[9], about[11], about[13], about[15], about[16], about[17], about[18]);

  return (
    <PageTemplate>
      <HeroImage>
        <HeroSectionImage>
          <Img fluid={dataImage.about_bg.childImageSharp.fluid} />
        </HeroSectionImage>
        <Styled.QuoteWrapper>
          <QuoteIcon />
          <Styled.QuoteContent notAnimate>{formatMessage({ id: 'quote.title' })}</Styled.QuoteContent>
        </Styled.QuoteWrapper>
      </HeroImage>
      <ContentWrapper>
        <ContentRow top>
          <Helmet htmlAttributes={{ lang: `${locale}` }}>
            <title>Agnieszka Kazmierczyk - O mnie</title>
            <meta name="description" content="Some content." />
          </Helmet>
          <ContainerItems>
            <ContainerContent>
              <SectionHeading>{formatMessage({ id: 'about.title' })}</SectionHeading>
              <ContainerImgWrapper>
                <Img fluid={aboutData.img.childImageSharp.fluid} alt="Agnieszka Kazmierczyk" />
              </ContainerImgWrapper>
              {aboutIntro.map((intro: string, index: number) => {
                return <SectionParagraph key={index} dangerouslySetInnerHTML={{ __html: intro }} />;
              })}
            </ContainerContent>
            <ContainerDescription>
              {aboutDesc.map((desc: string, index: number) => {
                return <SectionParagraph key={index} dangerouslySetInnerHTML={{ __html: desc }} />;
              })}
            </ContainerDescription>
            <ButtonsContainer space>
              <Button as={Link} to="/books" icon="true" aria-label={formatMessage({ id: 'about.myBooks' })}>
                <ButtonText>{formatMessage({ id: 'about.myBooks' })}</ButtonText>
                <ButtonIconArrow className="arrow--icon">
                  <ArrowIcon />
                </ButtonIconArrow>
              </Button>
              <Button
                as={Link}
                to="/contact"
                icon="true"
                secondary="true"
                aria-label={formatMessage({ id: 'contact.title' })}
              >
                <ButtonText>{formatMessage({ id: 'contact.title' })}</ButtonText>
                <ButtonIconArrow className="arrow--icon">
                  <ArrowIcon />
                </ButtonIconArrow>
              </Button>
            </ButtonsContainer>
          </ContainerItems>
        </ContentRow>
      </ContentWrapper>
    </PageTemplate>
  );
};

export default About;
