import { graphql, useStaticQuery } from 'gatsby';

const data = {
  pl: {
    content: `<p><strong>Agnieszka Kaźmierczyk</strong> (ur. 11 marca 1984r. w Chrzanowie).</p>\n<p>Z wykształcenia polonistka i dziennikarka. Absolwentka Uniwersytetu Pedagogicznego w Krakowie (Filologia polska, Studium Dziennikarskie)</p>\n<p>&nbsp;</p>\n<p>W latach 2006-2007 współpracownik „Dziennika Polskiego”– dział kulturalny, współwydawca dodatku „Student”</p>\n<p>W latach 2009-2010 redaktor w lokalnym tygodniku „Jaworzno 24” oraz portalu o tej samej nazwie.</p>\n<p>Autorka powieści obyczajowych i young adult.</p>\n<p>Wielbicielka mitologii i demonologii słowiańskiej.</p>\n<p>Współtwórca portalu dla rodziców multirodzice.pl.</p>\n<p><strong>Prywatnie:</strong><br />\nDziewczyna chłopaka w niebieskiej kurtce<br />\nMama dwóch takich, dla których warto wstawać<br />\nPowsinoga<br />\nUparciuch</p>\n`,
  },
  en: {
    content: `<p>Agnieszka Kaźmierczyk (born 11 March 1984 in Chrzanów).</p>\n<p>Polish philologist and journalist by education. A graduate of the Pedagogical University in Cracow (Polish Philology, Journalism Studies).</p>\n<p>In the years 2006-2007 a collaborator of &#8220;Dziennik Polski&#8221; &#8211; cultural department, co-publisher of the &#8220;Student&#8221; supplement.</p>\n<p>In 2009-2010 he was an editor in the local weekly magazine &#8220;Jaworzno 24&#8221; and the portal of the same name.</p>\n<p>Author of moral novels and young adult.</p>\n<p>Fan of Slavic mythology and demonology.</p>\n<p>Co-founder of the portal for parents multirodzice.pl.</p>\n<p><strong>Privately:</strong><br />\nGirlfriend of the boy in the blue jacket<br />\nMommy&#8217;s two of those for whom it&#8217;s worth getting up<br />\nPowsinoga<br />\nStubbornness</p>\n`,
  },
};

export const useAboutQuery = () => {
  const { file } = useStaticQuery(
    graphql`
      query AboutQuery {
        file(relativePath: { eq: "photo.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  );

  return { ...data, img: file };
};
